.loader {
    display: flex;
    position: fixed;
    top: 50%;
    /* align the top of the popup to the middle of the window */
    left: 50%;
    /* align the left of the popup to the middle of the window */
    transform: translate(-50%, -50%);
    /* center the popup */
    max-width: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    z-index: 2;
    /* transition: all 0.3s ease; */
    font-size: calc(2vw + 2vh);
    /* make sure the popup is on top of the backdrop */
}